import * as React from 'react';

import {Elements,} from '@stripe/react-stripe-js';

import {loadStripe} from "@stripe/stripe-js/pure";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe('pk_live_51K5C0AA6VveU2lOJVmtvIi2Kb2MScTqMYSwhoouoFQxXG5H61QSurMBA3AoRP8tJSu8xrNFmswhdfJTf1vnvnnSD00Ro8vjYTA');
// const stripePromise = loadStripe('pk_test_51K5C0AA6VveU2lOJA8jbsdsNuFTHB0LATj1nKE9WvCyWvQjxsxCGKJasCXARV125h9kQy8k8uFRg9wCsWBMrBfVx00cKMG3ATz');



export default function SignUp() {
  

  return (  

            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
  


  );
}