

module.exports = {
    getDayBoundaries: (data) => {

        const date = new Date();
		const offset = Math.round(date.getTimezoneOffset()/60); //get offset in hours

		const day_names = ['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa', 'Su', 'M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];
		const day1 = new Date(data.data[1].dattim);

		const days = Array.from({length: 10}, (_, i) => i + day1.getDay() + 1);
		const day_labels = days.map(function (i) { return day_names[i] });

        return {
            date: date,
            offset: offset,
            day_names: day_names,
            day1: day1,
            days: days,
            day_labels: day_labels,
        }
    }
};
