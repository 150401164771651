import React, { useState, useEffect } from 'react';


import MapChartPrecip from './MapChartPrecip4';
import MapChartTemp from './MapChartTemp';

import MapChartSnow from './MapChartSnow';
import MapChartWind from './MapChartWind';
// import MapChartWind from './MapChartWind2';
        
import fetchFile from './fetchFile';

function Terra(){

  const [weather_type, setWeatherType] = useState('precipitation');
  const [messages, setMessages] = React.useState([]);
   
   useEffect(() => {
    const file_name = 'terra_notifications.json';
    fetchFile.getFileFromS3(file_name).then(response => {
      console.log('Output:', JSON.parse(response.Body));
      console.log('S3 notification file fetched successfuly!');
      const data = JSON.parse(response.Body);
      // console.log("data")
      // console.log(data.messages)

      setMessages(data.messages);

    })
    console.log("messages")
    console.log(messages)

    }, [messages]);


  // this function will be called when a radio button is checked
  const handleChange = (e) => {
    
    setWeatherType(e.target.value);
    
  }


  return (
    //<React.Fragment>
    
      <div className='terra-div' style={{backgroundColor: 'white'}}>
          <div className='blue-patch-div'></div>            

          <div id="main-box">
            <form>
            <div id="weather-type-selection-div" class="thin-box-div">
              <u><h3><center>Select weather type</center></h3></u>

              <input
                type="radio"
                name="weather_type"
                id='precip-button-1'
                value="precipitation"
                onChange={handleChange}
                checked={weather_type === 'precipitation'} 
                // checked
                />
              <label htmlFor="precip-button-1">Precipitation</label><br />
              
              <input
                type="radio"
                name="weather_type"
                id='snow-button-1'
                value="snow"
                onChange={handleChange}
                checked={weather_type === 'snow'} />
              <label htmlFor="snow-button-1">Snow</label><br />

              <input
                type="radio"
                name="weather_type"
                id='temp-button-1'
                value="temperature"
                onChange={handleChange}
                checked={weather_type === 'temperature'} />
              <label htmlFor="temp-button-1">Temperature</label><br />

              <input
                type="radio"
                name="weather_type"
                id='wind-button-1'
                value="wind"
                onChange={handleChange}
                checked={weather_type === 'wind'} />
              <label htmlFor="wind-button-1">Wind</label><br />
              
            </div>
            </form>


            <div id='tips-notifications-div'>
            
              <div id="tips-notifications-general-div" class="wide-box-div">
                <div id='tips-list-div'>
                  <u><h3><center>Tips</center></h3></u>
                  <ul>
                    <li>If on mobile, try using landscape mode for best user experience</li>
                    <li>Temperature & wind are bigger datasets and may take longer to load.</li>
                    <li>Slider time increments are binned in three categories. Pay close attention to the date, see <a href="/faqs">here</a> for more information. </li>
                  </ul>
                </div>

                <div id='terra-notifications-list-div'>
                  <u><h3><center>Important Notifications</center></h3></u>
                  <ul class="myUL">{messages.map(({ id, content}) => (
                    <li>
                      {content}
                    </li>
                  ))}
                  </ul>
                </div>
                
                <div id='general-message-div'><p>Thanks for being a member with us, we appreciate your business.
                      For more information, see our <a href="/faqs">FAQ</a> page, but if you have any questions or want to provide any feedback feel free 
                      to <a href="mailto:support@climatedev.com">contact us</a> at any time.
                    </p>
                </div> 

              </div>             
            </div>

          </div>

  
        <div id='main-box-2'>
          <h1 style={{ color: '#2E3B55' }}>{weather_type === undefined ? 'Please select your weather type' : `Your weather type: ${weather_type} data`}</h1>
        </div>
        {(() => {
          if (weather_type==='precipitation') {
            return (
              <MapChartPrecip weather_type={weather_type} public_bool={"False"}></MapChartPrecip> 
            )
          } else if (weather_type==='temperature') {
            return (
              <MapChartTemp weather_type={weather_type} public_bool={"False"}></MapChartTemp>
            )
          } else if (weather_type==='snow') {
            return (
              <MapChartSnow weather_type={weather_type} public_bool={"False"}></MapChartSnow>
            )
          } else if (weather_type==='wind') {
            return (
              <MapChartWind weather_type={weather_type} public_bool={"False"}></MapChartWind>
            )
          } else {
            return (
              <MapChartPrecip weather_type={weather_type} public_bool={"False"}></MapChartPrecip> 
            )
          }
      })()}
      </div>
    

    //</React.Fragment>
    
  );
  
}

export default Terra;