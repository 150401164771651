import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

// export default axios.create({
//   baseURL: `https://jetstreamjet.com/`
// });

// export const API_URL ='http://localhost:8000/power'
export const API_URL ='https://climatedev.com/power'

export const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-type": "application/json"
    }
});

 export default class ApiService{
     static saveStripeInfo(data={}){
         return api.post(`${API_URL}/payments/save-stripe-info/`, data)
     }
     static saveSignupInfo(data={}){
      return api.post(`${API_URL}/api/auth/register/`, data)
  }
 }