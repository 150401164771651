import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const ContactUs = () => {

  return (
    <Box m={2} pt={1}>
      <Grid container spacing={6} >

        <Grid item xs={24} md={10}>
          <Typography variant="h3" fontWeight={700} >
            Contact Us
          </Typography>
          <Typography >
          Thanks for visiting our site. For more information, see our <a href="/faqs">FAQ</a> page, 
          but if you have any questions or want to provide any feedback feel free 
          to <a href="mailto:support@climatedev.com">contact us</a> at any time.
          </Typography>

        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;