import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import './Terra.css';  

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 30,
    // flexGrow: 1,
  },

  linkelements: {
    marginRight: 10,
  },

}));

export default function TopBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: '#2E3B55', height: 60 }}>
      {/* <AppBar > */}
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Terra Weather
          </Typography>
          
          {props.isAuthenticated ? 
            null : 
            // <Button color="inherit" href="/questions">Polls</Button>
              <IconButton className={classes.righticon} aria-label="home page" color="inherit" href="/terra">
                <HomeIcon />
              </IconButton>            
            }

          {/* <IconButton className={classes.righticon} aria-label="home page" color="inherit" href="/">
            <HomeIcon />
          </IconButton> */}
          {/* {props.isAuthenticated ? <Button color="inherit" href="/questions">Polls</Button> : null} */}
          {props.isAuthenticated ? <Button className={classes.linkelements} color="inherit" href="/terra">Terra</Button> : null}
          {props.isAuthenticated ? <Button className={classes.linkelements} color="inherit" href="/update_password">Update Password</Button> : null}
          {props.isAuthenticated ? <Button className={classes.linkelements} color="inherit" onClick={() => props.logout()}>Logout</Button> : null}
        </Toolbar>
      </AppBar>
      {/* <Toolbar />  */}
    </div>
  );
}
