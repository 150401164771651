import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import snowGif from '../images/snowmovie4.gif';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

const Img = styled('img')({
  marginTop: '50px',
  padding: '10px',
  marginLeft: '300px',
  maxWidth: '200%',
  maxHeight: '200%',
});

const AboutUs = () => {

  return (
    <Box m={2} pt={5} pb={3}>
      <Grid container spacing={6} >
        <Grid item xs={6}>
          <ButtonBase sx={{ width: 300, height: 300 }}>
            <Img alt="complex" src={snowGif} />
          </ButtonBase>
        </Grid>

        <Grid item xs={6} >
          <Typography variant="h3" fontWeight={700}>
            Data Science & Data Engineering
          </Typography>
          <Typography >
          Terra provides data from the National Weather Service's National Blend of Models (NBM). This data is considered both a skillful and consistent model composite based off of the combination of several standalone models.
          We refresh our data with an innovative cloudless caching technology and with thoughtful design choices we can vizualize a massive amount of data. 
          Check out our demo page now!
          </Typography>
          <Button
            href='/terrapublic/'
            variant="contained"
            color="primary"
            sx={{ width: '280px', height: '70px', fontSize: '16px',  margin: '9px' }}
          >
            <center>Go To Demo <br></br>(mobile? Rotate screen )</center>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;