import React from 'react';
import { Typography } from '@mui/material';
import { Link } from '@mui/material';

function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://climatedev.com/">
        Climate Dev Data Engineering
      </Link>
      {' '}

      {new Date().getFullYear()}
      .
      {' '}
      <Link color="inherit" href="https://terraterms.s3.us-west-1.amazonaws.com/privacy_policy.pdf">
        Privacy Policy
      </Link>
      {' '}
      <Link color="inherit" href="https://terraterms.s3.us-west-1.amazonaws.com/termsAndConditions.pdf">
        Terms and Conditions
      </Link>
      {' '}
      {' '}
      <Link color="inherit" href="/about">
        About
      </Link>
      {' '}

    </Typography>
  );
}
export default Footer;
