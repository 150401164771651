import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import precipmovie5 from '../images/precipmovie5.gif';

const Img = styled('img')({
  margin: '20px',
  padding: '10px',
  marginLeft: '100px',
  maxWidth: '200%',
  maxHeight: '200%',
});

export default function ComplexGrid() {
  return (

    <Box m={2} pt={5} pb={3}>
        <Grid container spacing={20}>
            <Grid item xs={7}>
                <Typography variant="h3" fontWeight={700} >
                    10 Day Weather Radar
                </Typography>
                <Typography variant="h6" >
                    Our app, Terra, provides long-range weather radar forecasts. If your organization 
                    operates inter-state travel or transportation, consider our
                    app for insight into how to route trucks or plan vacations to avoid bad weather 
                    conditions.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: '250px', height: '70px', fontSize: '16px', margin: '9px'  }}
                    href='/signup/'
                >
                    Sign Up Here 
                </Button>
                <Button
                    href='/terrapublic/'
                    variant="contained"
                    color="primary"
                    sx={{ width: '280px', height: '70px', fontSize: '16px',  margin: '9px' }}
                >
                    <center>Go To Demo <br></br>(mobile? Rotate screen )</center>
                </Button>

            </Grid>

            <Grid item xs={5}>
                <ButtonBase sx={{ width: 250, height: 250 }}>
                    <Img alt="complex" src={precipmovie5} />
                </ButtonBase>
            </Grid>
        </Grid>
    </Box>
  );
}
