import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ThunderstormOutlinedIcon from '@mui/icons-material/ThunderstormOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const Section = () => {

  const sectionItems = [
    {
      id: 1,
      icon: <ThunderstormOutlinedIcon sx={{ fontSize: 100 }} color="primary" />,
      sentence:
        'Effectively designed to display the next generation of weather data from the National Blend of Models.',
    },
    {
      id: 2,
      icon: <LocalShippingOutlinedIcon sx={{ fontSize: 100 }} color="primary" />,
      sentence:
        'Optimize travel and transportation routes with Terra.',
    },
    {
      id: 3,
      icon: <EngineeringOutlinedIcon sx={{ fontSize: 100 }} color="primary" />,
      sentence: 'Prepare and organize utility maintenance teams for hazardous weather events.',
    },
  ];
  return (
    <Box mb={5} sx={{ flexGrow: 1, maxHeight: '150px' }}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
      >
        {sectionItems.map((item) => (
          <Grid
            item
            xs={12}
            md={3.5}
            minHeight={300}
            key={item.id}
          >
            {item.icon}
            <Typography>{item.sentence}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section;