import React from 'react';          

function About(){
  // TODO: Add styling/ CSS
  return (
    
<div style={{backgroundColor: 'white'}}>

            <h1><center>Welcome to Terra!</center></h1>
            
            <center><img src="https://cddevbucket.s3.us-west-1.amazonaws.com/climate_dev_logo_20211203.png" alt="Logo" width="80%"></img></center>
            {/* <img src="https://images.unsplash.com/photo-1514632595-4944383f2737?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="Stock weather image" style="float:left; margin-right: 20px;" width="400" height="500"></img> */}
            <p>Our mission is to reliably provide you with the most up to date and accurate
            weather information to aid in your decision making. From aviation to event planning,
            we know the importance of providing the best and most accesible forecasting tools to
            you when you need them!</p>
            <br></br>
            <p>Terra provides data from the <a href="https://vlab.noaa.gov/web/mdl/nbm">National Weather
            Service's National Blend of Models (NBM).</a> This data is considered both a <a href=
            "https://vlab.noaa.gov/web/mdl/nbm-performance">skillful and consistent</a> model
            composite based off of the combination of several standalone model solutions. </p>
            <br></br>
            <p>We refresh our data every hour using our innovative cloudless caching technology and
            provide our web interface via <a href="https://kubernetes.io/">Kubnetes Cluster. </a>
            This means that we can serve you updated data more efficiently and with less downtime
            than can be found anywhere else!
            <br></br>
            Interested in learning more? <a href="https://climatedev.com/terrapublic"> Check 
            out our demo page now!</a></p>

      </div>

)};

export default About;