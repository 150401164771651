const AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET,
    region: 'us-west-1'
});
const S3 = new AWS.S3();

module.exports = {
    getFileFromS3: (file_name) => {
        return new Promise((resolve, reject) => {
            try {
                const bucketName = 'terradev';
                const objectKey = file_name;
                S3.getObject({
                    Bucket: bucketName,
                    Key: objectKey
                }, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        console.log('Unparsed Fetched Object Data:', data);
                        resolve(data);
                    }
                });
            } catch(e) {
                reject(e);
            }
        });
    }
};
