import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useState} from "react";
import ApiService from "../api";
// import axios from 'axios';



import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory } from "react-router-dom";

import './Checkout.css';
import CSRFToken from './csrftoken';

import { ThreeCircles } from "react-loader-spinner";

const theme = createTheme();

const CheckoutForm = () => {
        const history = useHistory();
        const [error, setError] = useState(null);
        const [email, setEmail] = useState('');
        // const [paid, setPaid] = useState('False');
        const stripe = useStripe();
        const elements = useElements();
        const [loading, setloading] = useState(false);

        // Handle real-time validation errors from the card Element.
        const handleChange = (event) => {
            if (event.error) {
                setError(event.error.message);
            } else {
                setError(null);
            }
        }

        const [agreement, setAgreement] = useState(false);
        const handleTermsCheckChange = (event) => {
          setAgreement(event.target.checked);
        }

        // Handle form submission.
        const handleSubmit = async (event) => {

                event.preventDefault();
                const data = new FormData(event.currentTarget);

                const card = elements.getElement(CardElement);

                const {paymentMethod, error} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: card,
                });
                console.log(paymentMethod)
                console.log(email)
                setloading(true)

                if (error) {
                    setError(error.response.data)
                }
                else if (data.get('userName').length < 10){
                    setError('username must have at least 10 characters')
                }
                else if (agreement !== true){
                    setError('Agree to the terms and agreements')
                }
                else {
                    ApiService.saveStripeInfo({
                        payment_method_id: paymentMethod.id,
                        username : data.get('userName'),
                        password : data.get('password'),
                        password2 : data.get('password2'),
                        email : data.get('email'), 
                        first_name : data.get('firstName'), 
                        last_name : data.get('lastName'),
                    })
                    .then(response => {
                        console.log(response.data);
                        setloading(false)
                        history.push('/signupsuccess')
                    }).catch(error => {
                        console.log(error)
                    })
                };

                console.log("done with checkout")
                
            }
        ;

        return (

            <ThemeProvider theme={theme}>
                
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>

                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                            <TextField
                            autoComplete="user-name"
                            name="userName"
                            required
                            fullWidth
                            id="userName"
                            label="User Name"
                            
                            />
                        </Grid>
                        
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        name="password2"
                        label="Password2"
                        type="password"
                        id="password2"
                        autoComplete="new-password"
                        />
                    </Grid>
                    {/* <Grid container spacing={2}> */}
                    <Grid item xs={12} sm={6}>
                        <p>What color are fire trucks?</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        required
                        fullWidth
                        id="questionresponse"
                        label="Question Response"
                        name="questionResponse"
                        autoComplete="question-response"
                        />
                    </Grid>  
        
                    </Grid>

                    <div className="form-row">
                        <label htmlFor="card-element">
                            Credit or debit card
                        </label>

                        <CardElement
                            id="card-element"
                            onChange={handleChange}
                        />
                        <div className="card-errors" role="alert">{error}</div>
                    </div>

                    <Grid item xs={12}>
                    <div id="upon-signup-div">
                        *Upon signup, the user will be forwarded to a welcome page and charged $12/month.
                    </div>

                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                        control={<Checkbox value="agreetoterms" color="primary" onChange={handleTermsCheckChange}/>}
                        label={
                            <div>
                                <span>I accept the </span>
                                <a href="https://terraterms.s3.us-west-1.amazonaws.com/termsAndConditions.pdf">terms of use</a>
                                <span> and </span>
                                <a href='https://terraterms.s3.us-west-1.amazonaws.com/privacy_policy.pdf'>privacy policy</a>
                            </div>
                        }
                        />
                        
                    </Grid>
                    

                    <CSRFToken />


                    {
                        loading ?

                            <center><ThreeCircles color={'#2E3B55'} loading={loading} size={50} /></center>
                            :

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                >
                                Sign Up
                            </Button>
                    }

                    <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/terra" variant="body2">
                        Already have an account? Sign in
                        </Link>
                    </Grid>
                    </Grid>
                </Box>
                </Box>
            </Container>
            <br></br>
            </ThemeProvider>


        );

    }
;

export default CheckoutForm;