import React from 'react';          

function FAQ(){
  // TODO: Add styling/ CSS
  return (
      <div>
        <center><img src="https://cddevbucket.s3.us-west-1.amazonaws.com/climate_dev_logo_20211203.png" alt="Logo" height="10%"></img>
        <h1>Frequently Asked Questions</h1>
        <br></br>
        <h2>How often is your data refreshed?</h2>
        <p>New data is available from the NWS every hour which is pulled into our system
        as it becomes available.</p>
        <br></br>
        <h2>Is there a delay in the availability of the data?</h2>
        <p>The data is delayed by about an hour and a half due to processing time by NOAA's
        servers. This means that the latest model data being viewed at any given time is
        actually forecasted out by about 1-2 hours. We trim the data that has expired so
        our model views start from the current time.</p>
        <br></br>
        <h2>Where can I see the raw data files? And is it free to access?</h2>
        <p>Yes, anyone can view the data! We retreive it from <a 
        href="https://noaa-nbm-grib2-pds.s3.amazonaws.com/index.html">NOAA's NBM data 
        archive on AWS</a></p>
        <br></br>
        <h2>Is your data free to access?</h2>
        <p>We offer a free trial period to give you a chance to explore our data prior
        to committing to long term access. This trial period is fourteen (14) days.</p>
        <br></br>
        <h2>Where can I find more information about the model used to generate the output data?</h2>
        Terra provides data from the <a href="https://vlab.noaa.gov/web/mdl/nbm">National Weather
        Service's National Blend of Models (NBM).</a>
        <br></br>
        <h2>What if I need to cancel my subscription?</h2>
        A cancellation page is coming soon, but for now please contact support@climatedev.com to handle that.
        </center>
        <br></br>
      </div>
      
  );

}

export default FAQ;