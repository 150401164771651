import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from "./components/Login";
import TerraPublic from "./components/TerraPublic";
import Terra from "./components/Terra";
import PasswordUpdate from "./components/PasswordUpdate";
import SignUp from "./components/SignUp";
import SignUpSuccess from "./components/SignUpSuccess";
import Welcome from "./components/Welcome";

import FAQ from "./components/FAQ";
import About from "./components/About";

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
function PrivateRoute({ isAuthenticated, children, ...rest}) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

function Urls(props) {

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login/"> <Login {...props} /></Route>
                    <Route exact path="/welcome/"> <Welcome {...props} /></Route>
                    <Route exact path="/signup/"> <SignUp {...props} /></Route>
                    <Route exact path="/signupsuccess/"> <SignUpSuccess {...props} /></Route>
                    <Route exact path="/faqs/"> <FAQ {...props} /></Route>
                    <Route exact path="/about/"> <About {...props} /></Route>
                    <Route exact path="/terrapublic/"> <TerraPublic {...props} /></Route>
                    <Route exact path="/"> <Welcome {...props} /></Route>
                    <PrivateRoute exact path="/terra/" isAuthenticated={props.isAuthenticated}> <Terra {...props} /></PrivateRoute>
                    <PrivateRoute exact path="/update_password/" isAuthenticated={props.isAuthenticated}><PasswordUpdate {...props}/></PrivateRoute>
                </Switch>
            </BrowserRouter>
        </div>
    )
};

export default Urls;