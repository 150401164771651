import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { sliderBottom } from 'd3-simple-slider';
import './Terra.css';
import * as topojson from 'topojson-client';
import us from './data/states-albers-10m.json';
// import data from './data/us-state-capitals.json';


import fetchFile from './fetchFile'
import dayBoundaryFunc from './dayBoundaryFunc'

import {publicprivateswitchbufferfunc, publicprivateswitchdatafunc} from './PublicPrivateSwitchFuncs';

const MapChart = (props) => {

	const d3Chart = useRef()
	const d3Slider = useRef()


	useEffect(() => {


		console.log("weather_type")
		console.log(props.weather_type)
		// console.log("public_bool")
		// console.log(props.public_bool)

		const path = d3.geoPath();
		const margin = {top: 100, right: 30, bottom: 50, left: 70}
		const width = 975;
		const height = 610;
		const projection = d3.geoAlbersUsa().scale(1300).translate([width / 2, height / 2]); 

		const mydomain = [0, 25, 50];
		var myColor = d3.scaleLinear().domain(mydomain)
			.range(["green", "orange", "red"])
	

		// Set up chart
		var svg = d3.select(d3Chart.current)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.style('background-color', '#262626')
			.append('g')
				.attr('transform', 'translate('+ margin.left + ',' + margin.top + ')');

		
		//title
		svg.append("text")
			.attr("x", "-50")
			.attr("y", "-60")
			.attr("text-anchor", "start")
			.style("font-size", "16px")
			.text("Projection for: " + props.weather_type)
			.style('fill', 'white');


		//units
		svg.append("text")
		.attr("x", "-50")
		.attr("y", "-35")
		.attr("text-anchor", "start")
		.style("font-size", "16px")
		.text("Units: mph" )
		.style('fill', 'white');


		//loading text
		svg.append("text")
			.attr("class", "update")
			.attr("x", width/2 + 50)
			.attr("y", "10")
			.attr("text-anchor", "middle")
			.style("font-size", "16px")
			.text("Loading More Data - Please Be Patient.")
			.style('fill', 'yellow');


		// Draw state lines
		svg.append('path')
			.attr("d", path(topojson.feature(us, us.objects.states)))
			.attr('fill', '#ddd');


		const tooldiv = d3.select("#d3demo")
		  .append("div")
		  .style("visibility", 'hidden')
		  .attr("class", "tooltip")
		  .style("position", 'absolute')
		  .style("background-color", "grey")
		  .style("border", "solid")
		  .style("border-width", "2px")
		  .style("border-radius", "5px")
		  .style("padding", "5px")
	  

		//legend
		//Append a defs (for definition) element to your SVG
		var defs = svg.append("defs");


		var linearGradient = defs.append("linearGradient")
		.attr("id", "linear-gradient");

		//Vertical gradient
		linearGradient
			.attr("x1", "0%")
			.attr("y1", "0%")
			.attr("x2", "0%")
			.attr("y2", "100%");

		//Set the color for the start (0%)
		linearGradient.append("stop")
			.attr("offset", "0%")
			.attr("stop-color", "red"); //last color sent in props

		//Set the color for the end (100%)
		linearGradient.append("stop")
			.attr("offset", "50%")
			.attr("stop-color", "orange"); //yellow

		//Set the color for the end (100%)
		linearGradient.append("stop")
			.attr("offset", "100%")
			.attr("stop-color", "green"); //red

		// //Draw the rectangle and fill with gradient
		svg.append("rect")
			.attr('x', -30)
			.attr('y', 0)
			.attr("width", 20)
			.attr("height", 500)
			.style("fill", "url(#linear-gradient)");

		// update(1) // start on increment 1

		var y = d3.scaleLinear()
			// .domain([50, 0])         // This is what is written on the Axis: from 0 to 100
			.domain([Math.max(...mydomain), Math.min(...mydomain)])         // This is what is written on the Axis: from 0 to 100
			.range([100, 600]);       // This is where the axis is placed: from 100px to 800px
		
		// // Draw the axis
		svg
		  .append("g")
		  .attr("transform", "translate(-30,-100)")      // This controls the vertical position of the Axis
		  .attr("class", "axisWhite")
		  .call(d3.axisLeft(y));

		// Show the first frame of data
		var file_name1 = 'wind';

		var file_name = publicprivateswitchbufferfunc(props.public_bool, file_name1)

		try {
			
			fetchFile.getFileFromS3(file_name).then(response => {
				console.log('Output:', JSON.parse(response.Body));
				console.log('S3 buffer file fetched successfuly!');
				const data = JSON.parse(response.Body);

				console.log("data")
				console.log(data)

				var stateCapitalElements = svg.selectAll('g')
					.data(data.data[1].datasets)
					.enter()
					.append('g');

				stateCapitalElements.attr("class", "update");
			

				stateCapitalElements.append('g')
					.attr('transform', ({ longitude, latitude }) => `translate(${projection([longitude, latitude]).join(",")})`)
						.append('circle')
						.attr('r', 4)
						// .attr("fill", function(d){return myColor(d['wind_speed']) });
						.attr("fill", function(d){if(d['wind_speed'] > 10){
							return myColor(d['wind_speed'])
										} else { return "#dddddd"}})
	
						.style('stroke', function(d){if(d['wind_speed'] < 10){
												return "#46A401" 
										} })
						.style('stroke-width', 2)
						.attr('opacity', function(d){if(d['wind_speed'] < 5){
									return ".1" 
							} else {
									return "1"
							}
							})

				svg.append('path')
					.attr("d", path(topojson.mesh(us, us.objects.states, (a, b) => a !==b)))
					.attr('fill', 'none')
					.attr('stroke', '#fff')
					.attr('stroke-linejoin', 'round')
					.attr('stroke-linecap', 'round');

				//d3 value-simple
				svg.append("text")
					.attr("class", "buffertext")
					.attr("x", "900")
					.attr("y", "-12")
					.attr("text-anchor", "middle")
					.style("font-size", "16px")
					.text('Forcasted Hour: ' + data.data[1].forecast_hour)
					.style('fill', 'white');


				var utcDate = data.data[1].dattim;

				//d3 dattim
				svg.append("text")
					.attr("class", "buffertext")
					.attr("x", width/2 + 50)
					.attr("y", "-30")
					.attr("text-anchor", "middle")
					.style("font-size", "16px")
					.text(new Date(utcDate))
					.style('fill', 'white');

				//Draw the rectangle and fill with gradient
				svg.append("rect")
					.attr('x', -30)
					.attr('y', 0)
					.attr("width", 20)
					.attr("height", 500)
					.style("fill", "url(#linear-gradient)");

				// Draw the axis
				svg
				.append("g")
				.attr("transform", "translate(-30,-100)")      // This controls the vertical position of the Axis
				.attr("class", "axisWhite")
				.call(d3.axisLeft(y));

			}).catch((err) => {
				console.log(err.message);
				// console.log(err.message);
			});
		} catch (err) {
			console.log(err.message)
		}

		

		file_name = publicprivateswitchdatafunc(props.public_bool, file_name1)


		try {
			fetchFile.getFileFromS3(file_name).then(response => {
				// console.log('Output:', JSON.parse(response.Body));
				console.log('S3 data file fetched successfuly!');
				const data = JSON.parse(response.Body);

		console.log("Wdata2")
		console.log(data)

		const {offset, day_labels} = dayBoundaryFunc.getDayBoundaries(data)

		d3.select('#playbuttondiv')
			.style("visibility", 'visible');

		var dataslider = Object.keys(data.data) 


		dataslider = dataslider.map(function(str) {
			// using map() to convert array of strings to numbers
   
			return parseInt(str); });

		var sliderSimple = sliderBottom()
		  .min(d3.min(dataslider))
		  .max(d3.max(dataslider))
		  .width(500)
		  .tickValues(data.date_boundaries.map(v=> v+offset))
		  .tickFormat(function(d,i){ return day_labels[i] })
		  .step(1)
		  .default(1)
		  .on('onchange', val => {
			update(val);
			
		  });
	  
		//first tick  text patch
		svg.append("text")
			.attr("x", "372")
			.attr("y", "19")
			.attr("text-anchor", "middle")
			.style("font-size", "11px")
			.attr("font-weight", 800)
			// .style("font-family", "sans-serif")
			.text(day_labels[0])
			.style('fill', 'white');
		
		// Set up chart
		var gSimple = d3.select(d3Slider.current)
						.attr('width', 1000)
						.attr('height', 150)
						.append('g')
						.attr('transform', 'translate(390,105)');
	  
		gSimple.call(sliderSimple);
	  
		d3.select('p#value-simple').text((sliderSimple.value()));
		d3.select('p#value-simple').text((sliderSimple.value()));

		

		update(1) // start on increment 1

		

		function update(val) {
			//val 1, 2, 3

			svg.selectAll('.buffertext').remove()
			//d3 value-simple
			svg.append("text")
				.attr("class", "buffertext")
				.attr("x", "900")
				.attr("y", "-12")
				.attr("text-anchor", "middle")
				.style("font-size", "16px")
				.text('Forcasted Hour: ' + data.data[String(val)].forecast_hour)
				.style('fill', 'white');

			var utcDate = data.data[String(val)].dattim;
			// d3.select('p#value-dattim').text(new Date(utcDate));

			//d3 dattim
			svg.append("text")
				.attr('class', 'buffertext')
				.attr("x", width/2 + 50)
				.attr("y", "-30")
				.attr("text-anchor", "middle")
				.style("font-size", "16px")
				.text(new Date(utcDate))
				.style('fill', 'white');


			// svg.selectAll('g').remove()
			svg.selectAll('.update').remove()
			


			var stateCapitalElements = svg.selectAll('g')
				.data(data.data[String(val)].datasets)
				.enter()
				.append('g');

			stateCapitalElements.attr("class", "update");
			

			stateCapitalElements.append('g')
				.attr('transform', ({ longitude, latitude }) => `translate(${projection([longitude, latitude]).join(",")})`)
					.append('circle')
					.attr('r', 4)
					// .attr("fill", function(d){return myColor(d['wind_speed']) })
					.attr("fill", function(d){if(d['wind_speed'] > 10){
						return myColor(d['wind_speed'])
									} else { return "#dddddd"}})

					.style('stroke', function(d){if(d['wind_speed'] < 10){
											return "#46A401" 
									} })
					.style('stroke-width', 2)
					.attr('opacity', function(d){if(d['wind_speed'] < 5){
														return ".1" 
												} else {
														return "1"
												}
												})
				.on("mouseover", (e,d)=>{
					tooldiv.style('visibility', 'visible')
							// .html("Wind Speed: "+`${d3.format(".2f")(d['wind_speed'])}` +' mph' + "<br> Wind Direction: "+`${(d['wind_direction'])}` + "<br> Latitude: "+`${d3.format(".5f")(d.latitude)}` + "<br> Longitude: "+`${d3.format(".5f")(d.longitude)}`)
							.html(`Wind Speed: ${d3.format(".2f")(d['wind_speed'])} mph <br> Wind Direction: ${(d['wind_direction'])} <br> Latitude: ${d3.format(".5f")(d.latitude)}<br> Longitude: ${d3.format(".5f")(d.longitude)}`)

				})
				.on("mousemove", (e,d)=>{
					tooldiv.style('top', (e.pageY-100) + 'px')
							.style('left', (e.pageX-50) + 'px')
			
				})
				.on("mouseout", ()=>{
					tooldiv.style('visibility', 'hidden')
				})
				.on("touchstart", ()=>{
					tooldiv.style('visibility', 'visible')
				});

			svg.append('path')
				.attr("d", path(topojson.mesh(us, us.objects.states, (a, b) => a !==b)))
				.attr('fill', 'none')
				.attr('stroke', '#fff')
				.attr('stroke-linejoin', 'round')
				.attr('stroke-linecap', 'round');

			// //Draw the rectangle and fill with gradient
			svg.append("rect")
				// .attr("class", "update")
				.attr('x', -30)
				.attr('y', 0)
				.attr("width", 20)
				.attr("height", 500)
				.style("fill", "url(#linear-gradient)");
	

		}
		var timer;
		var playButton = d3.select("#play-button");

		playButton.on("click", function () {
			var button = d3.select(this);
			if (button.text() === "Pause") {
			 resetTimer();
			} else {
			  timer = setInterval(autostep, 500);
			  button.text("Pause");
			}
		  });

		function autostep() {
			var offset = parseInt(sliderSimple.value().valueOf()) + 1;
			sliderSimple.value(offset);
			//pause, uncomment to restart
			if(offset >= 98) {
			   resetTimer();
			 // sliderTime.value(startDate.valueOf());
			}
		  }

		function resetTimer() {
			  clearInterval(timer);
			 playButton.text("Play");
		  }

		}).catch((err) => {
			console.log(err.message);
			// console.log(err.message);
		});


	} catch (err) {
		console.log(err.message)
	}

	// } //end for
	
	  }, [props.public_bool, props.weather_type]);

	return (
		<div id='d3demo' >
			
			<div class="col-sm-2" className='slider'>
				<svg ref={d3Slider}></svg>
			</div>
			<div id='playbuttondiv' className='playbuttondiv'>
				<button id="play-button" className='play-button'>Play</button>
			</div>
			<div className='map'><svg ref={d3Chart} ></svg></div>			

		</div>
	)
}

export default MapChart;