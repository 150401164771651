import React from 'react';

export default class RegristrationSucess extends React.Component {

  render() {
    return (
      <center><div>
        <h4>
        Welcome to Terra! Your Registration was successful, thank you for signing up! Please <a href="/terra">sign in</a>
        </h4>

        <h4>
        If you have any questions, please reach out to support@climatedev.com
        </h4>
      </div></center>
    )
  }
}