export function publicprivateswitchbufferfunc(public_bool, file_name) {
    if(public_bool==="True"){
        file_name = file_name + '_buffer_demo.json'
        
    }
    else {
        file_name = file_name + '_buffer.json'
    };

    return file_name;
  }


  export function publicprivateswitchdatafunc(public_bool, file_name) {
    if(public_bool==="True"){
        file_name = file_name + '_demo.json'
        
    }
    else {
        file_name = file_name + '.json'
    };

    return file_name;
  }