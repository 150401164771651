import React from 'react';          
// import Hero from "./welcome/Hero"
import Hero from "./welcome/Hero2"
import AboutUs from "./welcome/AboutUs"
import Section from "./welcome/Section"
import ContactUs from "./welcome/ContactUs"
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },});

function Welcome(){
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Hero />
      <Section />
      <AboutUs />
      <ContactUs />
      </ThemeProvider>
    </>

)};

export default Welcome;